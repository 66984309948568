
// Libraries
import * as React from 'react'
import { navigate } from 'gatsby'
import moment from 'moment'

// Services
import BlogService from '../../../services/blog'

// Components
import PrivateRoute from '../../../components/privateRoute'
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import Hero from '../../../components/hero'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLink, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

const isBrowser = typeof window !== 'undefined'
const this_url = isBrowser ? window.location.href : process.env.APP_URL
const winHeight = 520
const winWidth = 350
const winTop = isBrowser ? (window.innerHeight / 2) - (winHeight / 2) : ''
const winLeft = isBrowser ? (window.innerWidth / 2) - (winWidth / 2) : ''

class AdminBlogPreviewPage extends React.Component {
	state = {
		blog: {}
	}

	render() {
		const {blog} = this.state

		return <PrivateRoute admin>
			<Layout>
				<Seo
					title={blog?.title}
					description={blog?.summary}
					image={blog?.image}
				/>

				<section itemScope itemType="https://schema.org/Blog">
					<Hero contentFullWidth={true} bg="blog" containerClass="container-xl">
						<div className="blog-hero">
							<h1 className="blog-hero__main-heading" itemProp="name">{blog?.title}</h1>
							<div className="blog-hero__overview">
								<div className="blog-hero__fields">
									{blog?.publish_at &&
										<div className="blog-hero__fields__date" itemProp="startDate" content={moment(blog?.publish_at).toISOString()}>
											{moment(blog?.publish_at).format('DD MMMM Y')}
										</div>
									}
								</div>
							</div>
						</div>
						{ blog?.image &&
							<div className="blog-hero__image">
								<a className="blog-go-back" href="/blog" onClick={this.goBack} target="_blank" rel="noreferrer">
									Back
									<FontAwesomeIcon icon={faArrowLeft} title="Arrow Left icon"/>
									<span className="sr-only"> Back</span>
								</a>
								<div className="blog-hero__image__container">
									<div className={`gatsby-image-wrapper gatsby-image-wrapper-constrained`}>
										<div style="max-width: 1000px; display: block;">
											<img alt=""
												 role="presentation"
												 aria-hidden="true"
												 src="data:image/svg+xml;charset=utf-8,%3Csvg height='563' width='1000' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
												 style="max-width: 100%; display: block; position: static;" />
										</div>
										<div aria-hidden="true" data-placeholder-image=""
											 style="opacity: 0; transition: opacity 500ms linear 0s; object-fit: cover;"></div>
										<picture>
											<source srcSet={blog.image} />
											<img itemProp="image" width="686" height="450"
												 data-main-image="" decoding="async"
												 alt={blog.title}
												 style="object-fit: cover; opacity: 1;" />
										</picture>
										<img width="1000" height="563" data-main-image="" decoding="async"
											 alt={blog.title} style="object-fit: cover; opacity: 1;" />
									</div>
								</div>
							</div>
						}
						<div className="blog-content" dangerouslySetInnerHTML={{ __html: blog?.content }} />
					</Hero>
					<div className="blog-share">
						<div className="container">
							<div className="share-wrapper">
								<h3>Share this article</h3>
								<ul className="footer-icons">
									<li>
										<a href="https://www.twitter.com" onClick={this.twitterShare} target="_blank"
										   rel="noreferrer" className="footer-icons__link">
											<FontAwesomeIcon icon={faXTwitter} title="X (Twitter) logo" />
											<span className="sr-only"> X (Twitter)</span>
										</a>
									</li>
									<li>
										<a href="https://www.facebook.com" onClick={this.fbShare} target="_blank"
										   rel="noreferrer" className="footer-icons__link">
											<FontAwesomeIcon icon={faFacebookF} title="Facebook logo" />
											<span className="sr-only"> Facebook</span>
										</a>
									</li>
									<li>
										<a href="https://www.linkedin.com" onClick={this.liShare} target="_blank"
										   rel="noreferrer" className="footer-icons__link linkedin">
											<FontAwesomeIcon icon={faLinkedin} title="LinkedIn logo"/>
											<span className="sr-only"> LinkedIn</span>
										</a>
									</li>
									<li>
										<a href="/blog" onClick={this.copyUrlToClipboard} target="_blank" rel="noreferrer" className="footer-icons__link">
											<FontAwesomeIcon icon={faLink} title="Link icon"/>
											<span className="sr-only"> Link</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</PrivateRoute>
	}

	componentDidMount() {
		const {slug} = this.props

		const blogService = new BlogService()
		blogService.get(slug)
			.then( (response) => {
				this.setState({
					blog: response.data
				})
			})
			.catch(err => console.error(err))
	}

	twitterShare(e){
		e.preventDefault()
		if (!isBrowser) {
			return false
		}
		const {blog} = this.state
		window.open('http://twitter.com/share?text='+ blog?.summary +'&url=' + this_url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight)
	}

	fbShare(e) {
		e.preventDefault()
		if (!isBrowser) {
			return false
		}
		const {blog} = this.state
		window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + blog?.title + '&p[summary]=' + blog?.summary + '&p[url]=' + this_url + '&p[images][0]=' + blog?.image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight)
	}

	liShare(e) {
		e.preventDefault()
		if (!isBrowser) {
			return false
		}
		const {blog} = this.state
		window.open('https://www.linkedin.com/shareArticle?url=' + this_url + '&title=' + blog?.title + '&summary=' + blog?.summary, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight)
	}

	copyUrlToClipboard(e){
		e.preventDefault()
		navigator.clipboard.writeText(this_url)
	}

	goBack(e){
		e.preventDefault()
		return navigate(-1)
	}
}

export default AdminBlogPreviewPage
